/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const SignOut = lazy(() => import('../pages/SignOut'))
const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
const Home = lazy(() => import('../pages/Home/Home'))
const Version = lazy(() => import('../pages/Version'))
const DialogDemo = lazy(() => import('../pages/DialogDemo/DialogDemo'))
const FilterDemo = lazy(() => import('../pages/FilterDemo'))
const ListPageDemo = lazy(() => import('../pages/ListPageDemo'))
const ActivityListPage = lazy(() => import('../pages/ActivityListPage'))
const ModulePage = lazy(() => import('../pages/ModulePage'))
const PatientsList = lazy(() => import('../pages/PatientsList'))
const PsychologistList = lazy(() => import('../pages/PsychologistList'))
const PsychologistPatientList = lazy(() => import('../pages/PsychologistPatientList'))
const Patient = lazy(() => import('../pages/Patient'))
const PatientProgramDetail = lazy(() => import('../pages/PatientProgramDetail'))
const ModuleList = lazy(() => import('../pages/ModuleList'))
const ProgramList = lazy(() => import('../pages/ProgramList'))
const TaskList = lazy(() => import('../pages/TaskList'))
const ProgramPage = lazy(() => import('../pages/ProgramPage'))
const NewTask = lazy(() => import('../pages/NewTask'))
const NewProgram = lazy(() => import('../pages/NewProgram'))
const NewModule = lazy(() => import('../pages/NewModule'))
const VideoPage = lazy(() => import('../pages/VideoPage'))
const BubblesPage = lazy(() => import('../pages/BubblesPage'))
const EscalePage = lazy(() => import('../pages/EscalePage'))
const BulletsPage = lazy(() => import('../pages/BulletsPage'))
const Case = lazy(() => import('../pages/Case'))
const CharPage = lazy(() => import('../pages/CharPage'))
const TabsDemo = lazy(() => import('../pages/TabsDemo'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))

const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  <UnauthorizedRoute
    path="/password_reset"
    redirectTo="/"
    exact
    component={PasswordReset}
  />,
  <Route path="/about" exact component={About} />,
  <AuthorizedRoute path="/my_account" exact component={MyAccount} />,
  <AuthorizedRoute path="/signout" exact component={SignOut} />,
  <AuthorizedRoute path="/home" exact component={Home} />,
  <AuthorizedRoute path="/dialog_demo" exact component={DialogDemo} />,
  <AuthorizedRoute path="/filter_demo" exact component={FilterDemo} />,
  <AuthorizedRoute path="/list_page_demo" exact component={ListPageDemo} />,
  <AuthorizedRoute path="/version/" exact component={Version} />,
  <AuthorizedRoute path="/paciente/actividades/modulo/:id" exact component={ActivityListPage} />,
  <AuthorizedRoute path="/actividades/programa/:id" exact component={ModulePage} />,
  <AuthorizedRoute path="/actividades/video/:id" exact component={VideoPage} />,
  <AuthorizedRoute path="/actividades/coleccionvinyeta/:id" exact component={BulletsPage} />,
  <AuthorizedRoute path="/actividades/burbuja/:id" exact component={BubblesPage} />,
  <AuthorizedRoute path="/actividades/escala/:id" exact component={EscalePage} />,
  <AuthorizedRoute path="/programa/escala/:user_id/:program_id" exact component={CharPage} />,
  <AuthorizedRoute path="/actividades/vinyeta/:id" exact component={Case} />,
  <AuthorizedRoute path="/patients_list" exact component={PatientsList} />,
  <AuthorizedRoute path="/psychologist_list" exact component={PsychologistList} />,
  <AuthorizedRoute path="/usuarios/user/:id?" exact component={PsychologistPatientList} />,
  <AuthorizedRoute path="/patient/usuarios/user/:id" component={Patient} />,
  <AuthorizedRoute path="/patient/usuarios/program/:userId/:programId" component={PatientProgramDetail} />,
  <AuthorizedRoute path="/module_list" exact component={ModuleList} />,
  <AuthorizedRoute path="/program_list" exact component={ProgramList} />,
  <AuthorizedRoute path="/task_list" exact component={TaskList} />,
  <AuthorizedRoute path="/actividades/actividad/:taskType?/:id?" exact component={NewTask} />,
  <AuthorizedRoute path="/actividades/modulo/:id?" exact component={NewModule} />,
  <AuthorizedRoute path="/programa/:id?" exact component={NewProgram} />,
  <AuthorizedRoute path="/program_page/" exact component={ProgramPage} />,
  <AuthorizedRoute path="/tabs_demo" exact component={TabsDemo} />,
]

export default routes
